export const contractAbi = [
   {
      inputs: [
         {
            internalType: "string",
            name: "name",
            type: "string",
         },
         {
            internalType: "string",
            name: "ticker",
            type: "string",
         },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
   },
   {
      anonymous: false,
      inputs: [
         {
            indexed: true,
            internalType: "address",
            name: "owner",
            type: "address",
         },
         {
            indexed: true,
            internalType: "address",
            name: "spender",
            type: "address",
         },
         {
            indexed: false,
            internalType: "uint256",
            name: "value",
            type: "uint256",
         },
      ],
      name: "Approval",
      type: "event",
   },
   {
      anonymous: false,
      inputs: [
         {
            indexed: false,
            internalType: "uint256",
            name: "idInvestment",
            type: "uint256",
         },
         {
            indexed: false,
            internalType: "string",
            name: "investor",
            type: "string",
         },
         {
            indexed: false,
            internalType: "address",
            name: "investorAddress",
            type: "address",
         },
         {
            indexed: false,
            internalType: "string",
            name: "collateralAssets",
            type: "string",
         },
         {
            indexed: false,
            internalType: "uint256",
            name: "floatDecimals",
            type: "uint256",
         },
         {
            indexed: false,
            internalType: "uint256",
            name: "interestRate",
            type: "uint256",
         },
         {
            indexed: false,
            internalType: "uint256",
            name: "investmentStartingDate",
            type: "uint256",
         },
         {
            indexed: false,
            internalType: "uint256",
            name: "investmentPaymentDate",
            type: "uint256",
         },
         {
            indexed: false,
            internalType: "uint256",
            name: "investmentAmount",
            type: "uint256",
         },
         {
            indexed: false,
            internalType: "uint256",
            name: "investmentDueAmount",
            type: "uint256",
         },
         {
            indexed: false,
            internalType: "string",
            name: "investmentTransaction",
            type: "string",
         },
      ],
      name: "InvestmentEvent",
      type: "event",
   },
   {
      anonymous: false,
      inputs: [
         {
            indexed: true,
            internalType: "address",
            name: "previousOwner",
            type: "address",
         },
         {
            indexed: true,
            internalType: "address",
            name: "newOwner",
            type: "address",
         },
      ],
      name: "OwnershipTransferred",
      type: "event",
   },
   {
      anonymous: false,
      inputs: [
         {
            indexed: false,
            internalType: "uint256",
            name: "idInvestment",
            type: "uint256",
         },
         {
            indexed: false,
            internalType: "string",
            name: "paymentTransaction",
            type: "string",
         },
      ],
      name: "PaymentEvent",
      type: "event",
   },
   {
      anonymous: false,
      inputs: [
         {
            indexed: true,
            internalType: "address",
            name: "from",
            type: "address",
         },
         {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
         },
         {
            indexed: false,
            internalType: "uint256",
            name: "value",
            type: "uint256",
         },
      ],
      name: "Transfer",
      type: "event",
   },
   {
      inputs: [],
      name: "COLLATERAL_ASSETS",
      outputs: [
         {
            internalType: "string",
            name: "",
            type: "string",
         },
      ],
      stateMutability: "view",
      type: "function",
      constant: true,
   },
   {
      inputs: [],
      name: "FLOAT_DECIMALS",
      outputs: [
         {
            internalType: "uint256",
            name: "",
            type: "uint256",
         },
      ],
      stateMutability: "view",
      type: "function",
      constant: true,
   },
   {
      inputs: [],
      name: "INVESTOR",
      outputs: [
         {
            internalType: "string",
            name: "",
            type: "string",
         },
      ],
      stateMutability: "view",
      type: "function",
      constant: true,
   },
   {
      inputs: [
         {
            internalType: "address",
            name: "owner",
            type: "address",
         },
         {
            internalType: "address",
            name: "spender",
            type: "address",
         },
      ],
      name: "allowance",
      outputs: [
         {
            internalType: "uint256",
            name: "",
            type: "uint256",
         },
      ],
      stateMutability: "view",
      type: "function",
      constant: true,
   },
   {
      inputs: [
         {
            internalType: "address",
            name: "spender",
            type: "address",
         },
         {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
         },
      ],
      name: "approve",
      outputs: [
         {
            internalType: "bool",
            name: "",
            type: "bool",
         },
      ],
      stateMutability: "nonpayable",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "address",
            name: "account",
            type: "address",
         },
      ],
      name: "balanceOf",
      outputs: [
         {
            internalType: "uint256",
            name: "",
            type: "uint256",
         },
      ],
      stateMutability: "view",
      type: "function",
      constant: true,
   },
   {
      inputs: [
         {
            internalType: "uint256",
            name: "",
            type: "uint256",
         },
      ],
      name: "collaterals",
      outputs: [
         {
            internalType: "address",
            name: "contractAddress",
            type: "address",
         },
         {
            internalType: "string",
            name: "ticker",
            type: "string",
         },
         {
            internalType: "uint256",
            name: "collateralAmount",
            type: "uint256",
         },
         {
            internalType: "uint256",
            name: "CPCAmount",
            type: "uint256",
         },
         {
            internalType: "string",
            name: "quotation",
            type: "string",
         },
         {
            internalType: "enum CPC.fiatCurrency",
            name: "currency",
            type: "uint8",
         },
      ],
      stateMutability: "view",
      type: "function",
      constant: true,
   },
   {
      inputs: [],
      name: "decimals",
      outputs: [
         {
            internalType: "uint8",
            name: "",
            type: "uint8",
         },
      ],
      stateMutability: "view",
      type: "function",
      constant: true,
   },
   {
      inputs: [
         {
            internalType: "address",
            name: "spender",
            type: "address",
         },
         {
            internalType: "uint256",
            name: "subtractedValue",
            type: "uint256",
         },
      ],
      name: "decreaseAllowance",
      outputs: [
         {
            internalType: "bool",
            name: "",
            type: "bool",
         },
      ],
      stateMutability: "nonpayable",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "address",
            name: "spender",
            type: "address",
         },
         {
            internalType: "uint256",
            name: "addedValue",
            type: "uint256",
         },
      ],
      name: "increaseAllowance",
      outputs: [
         {
            internalType: "bool",
            name: "",
            type: "bool",
         },
      ],
      stateMutability: "nonpayable",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "uint256",
            name: "",
            type: "uint256",
         },
      ],
      name: "investments",
      outputs: [
         {
            internalType: "uint256",
            name: "floatDecimals",
            type: "uint256",
         },
         {
            internalType: "uint256",
            name: "interestRate",
            type: "uint256",
         },
         {
            internalType: "uint256",
            name: "investmentStartingDate",
            type: "uint256",
         },
         {
            internalType: "uint256",
            name: "investmentPaymentDate",
            type: "uint256",
         },
         {
            internalType: "uint256",
            name: "investmentAmount",
            type: "uint256",
         },
         {
            internalType: "uint256",
            name: "investmentDueAmount",
            type: "uint256",
         },
         {
            internalType: "address",
            name: "investorAddress",
            type: "address",
         },
         {
            internalType: "bool",
            name: "isPaid",
            type: "bool",
         },
         {
            internalType: "string",
            name: "investmentTransaction",
            type: "string",
         },
      ],
      stateMutability: "view",
      type: "function",
      constant: true,
   },
   {
      inputs: [],
      name: "name",
      outputs: [
         {
            internalType: "string",
            name: "",
            type: "string",
         },
      ],
      stateMutability: "view",
      type: "function",
      constant: true,
   },
   {
      inputs: [],
      name: "owner",
      outputs: [
         {
            internalType: "address",
            name: "",
            type: "address",
         },
      ],
      stateMutability: "view",
      type: "function",
      constant: true,
   },
   {
      inputs: [
         {
            internalType: "uint256",
            name: "",
            type: "uint256",
         },
      ],
      name: "payments",
      outputs: [
         {
            internalType: "uint256",
            name: "idInvestmentOperation",
            type: "uint256",
         },
         {
            internalType: "string",
            name: "paymentTransaction",
            type: "string",
         },
      ],
      stateMutability: "view",
      type: "function",
      constant: true,
   },
   {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
   },
   {
      inputs: [],
      name: "symbol",
      outputs: [
         {
            internalType: "string",
            name: "",
            type: "string",
         },
      ],
      stateMutability: "view",
      type: "function",
      constant: true,
   },
   {
      inputs: [],
      name: "totalSupply",
      outputs: [
         {
            internalType: "uint256",
            name: "",
            type: "uint256",
         },
      ],
      stateMutability: "view",
      type: "function",
      constant: true,
   },
   {
      inputs: [
         {
            internalType: "address",
            name: "to",
            type: "address",
         },
         {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
         },
      ],
      name: "transfer",
      outputs: [
         {
            internalType: "bool",
            name: "",
            type: "bool",
         },
      ],
      stateMutability: "nonpayable",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "address",
            name: "from",
            type: "address",
         },
         {
            internalType: "address",
            name: "to",
            type: "address",
         },
         {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
         },
      ],
      name: "transferFrom",
      outputs: [
         {
            internalType: "bool",
            name: "",
            type: "bool",
         },
      ],
      stateMutability: "nonpayable",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "address",
            name: "newOwner",
            type: "address",
         },
      ],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "uint256",
            name: "interestRate",
            type: "uint256",
         },
         {
            internalType: "uint256",
            name: "investmentStartingDate",
            type: "uint256",
         },
         {
            internalType: "uint256",
            name: "investmentPaymentDate",
            type: "uint256",
         },
         {
            internalType: "uint256",
            name: "investmentAmount",
            type: "uint256",
         },
         {
            internalType: "uint256",
            name: "investmentDueAmount",
            type: "uint256",
         },
         {
            internalType: "address",
            name: "investorAddress",
            type: "address",
         },
         {
            internalType: "string",
            name: "investmentTransaction",
            type: "string",
         },
      ],
      name: "newInvestment",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "uint256",
            name: "idInvestment",
            type: "uint256",
         },
         {
            internalType: "string",
            name: "paymentTransaction",
            type: "string",
         },
      ],
      name: "newPayment",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "address",
            name: "contractAddress",
            type: "address",
         },
         {
            internalType: "uint256",
            name: "collateralAmount",
            type: "uint256",
         },
         {
            internalType: "uint256",
            name: "CPCAmount",
            type: "uint256",
         },
         {
            internalType: "string",
            name: "quotation",
            type: "string",
         },
         {
            internalType: "enum CPC.fiatCurrency",
            name: "currency",
            type: "uint8",
         },
      ],
      name: "addCollateral",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "uint256",
            name: "_id",
            type: "uint256",
         },
      ],
      name: "endCollateral",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
   },
   {
      inputs: [],
      name: "getCollateralList",
      outputs: [
         {
            components: [
               {
                  internalType: "address",
                  name: "contractAddress",
                  type: "address",
               },
               {
                  internalType: "string",
                  name: "ticker",
                  type: "string",
               },
               {
                  internalType: "uint256",
                  name: "collateralAmount",
                  type: "uint256",
               },
               {
                  internalType: "uint256",
                  name: "CPCAmount",
                  type: "uint256",
               },
               {
                  internalType: "string",
                  name: "quotation",
                  type: "string",
               },
               {
                  internalType: "enum CPC.fiatCurrency",
                  name: "currency",
                  type: "uint8",
               },
            ],
            internalType: "struct CPC.Collateral[]",
            name: "",
            type: "tuple[]",
         },
      ],
      stateMutability: "view",
      type: "function",
      constant: true,
   },
   {
      inputs: [],
      name: "getActiveInvestmentsCount",
      outputs: [
         {
            internalType: "uint256",
            name: "",
            type: "uint256",
         },
      ],
      stateMutability: "view",
      type: "function",
      constant: true,
   },
   {
      inputs: [],
      name: "getTotalInvestedAmount",
      outputs: [
         {
            internalType: "uint256",
            name: "",
            type: "uint256",
         },
      ],
      stateMutability: "view",
      type: "function",
      constant: true,
   },
   {
      inputs: [],
      name: "getTotalActiveInvestedAmount",
      outputs: [
         {
            internalType: "uint256",
            name: "",
            type: "uint256",
         },
      ],
      stateMutability: "view",
      type: "function",
      constant: true,
   },
   {
      inputs: [],
      name: "getTotalDueInvestedAmount",
      outputs: [
         {
            internalType: "uint256",
            name: "",
            type: "uint256",
         },
      ],
      stateMutability: "view",
      type: "function",
      constant: true,
   },
   {
      inputs: [],
      name: "getTotalPaidAmount",
      outputs: [
         {
            internalType: "uint256",
            name: "",
            type: "uint256",
         },
      ],
      stateMutability: "view",
      type: "function",
      constant: true,
   },
];
